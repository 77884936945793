import React from "react";
import { HeaderGeneral } from "../components/header-general";
import { Layout } from "../components/layout";
import SEO from "../components/seo";
import { MaxWidth, READ_WIDTH } from "../utils/style";
import { useStaticQuery, graphql } from "gatsby";
import RTF from "../components/rtf";
import SimpleReactLightbox from "simple-react-lightbox-pro";
import { SRLWrapper } from "simple-react-lightbox-pro";
import styled from "styled-components";
import { ContactForm } from "../components/contact-form";

const Impressum = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHtml(uid: { eq: "speaker-media" }) {
        content {
          json
        }
      }

      allContentfulSpMedia(limit: 500, sort: {fields: order, order: ASC}) {
        nodes {
          title
          order
          thumb {
            fixed(height: 180, width: 300) {
              base64
              src
            }
          }
          videoUrl
          media {
            fixed(height: 180, width: 300) {
              base64
              src
            }
            id
            file {
              url
              fileName
              contentType
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Medien" />
      <HeaderGeneral small background={require("../images/multimedia.jpg")}>
        <>
          <div className="circle-text">
            <h1>Medien</h1>
          </div>
          <div
            className="circle"
            style={{
              backgroundImage: `url(${require("../images/multimedia.jpg")})`,
            }}
          />
        </>
      </HeaderGeneral>
      <MaxWidth greyGradient>
        <div style={{ margin: "3rem 0" }}>
          {/* <RTF content={data.contentfulHtml.content.json} /> */}
        </div>

        <MediaLightbox data={data} />

        <ContactForm />
      </MaxWidth>
    </Layout>
  );
};

const VideoDiv = styled.div`
  > video {
    display: none;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

  > * {
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  .element_with_overlay {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    box-shadow: 0px 10px 20px -5px grey;
    .overlay {
      pointer-events: none;
      position: absolute;
      z-index: 9999;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      color: white;
      padding: 20px;
      display: flex;
      flex-direction: column;
      transform: translateY(100%);
      will-change: transform;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

      @media (max-width: 768px) {
        display: none;
      }

      h1 {
        line-height: 1.8rem;
        font-size: 1.5rem;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      p {
        font-weight: normal;
        line-height: 1rem;
        color: white;
      }
    }

    &:hover {
      .overlay {
        transform: translateY(0);
      }
    }
  }
`;

export default Impressum;

export const MediaLightbox = (props: any) => {
  const data = props.data;

  return (
    <SimpleReactLightbox>
      <SRLWrapper>
        <Wrap>
          {data.allContentfulSpMedia.nodes.map((x) => {
            const isVideo = (x.media?.file.contentType as string)?.startsWith(
              "video"
            ) || x.videoUrl;
            const videoUrl = x.videoUrl || x.media.file.url;
            const img = x?.thumb?.fixed?.src ?? x?.media?.fixed?.src;

            if (isVideo) {
              return (
                <VideoDiv className="element_with_overlay">
                  <div className="overlay" srl_overlay="true">
                    <p>{x.title}</p>
                  </div>
                  <img width="360px" src={img} alt={x.title} />
                  <video
                    width="360px"
                    srl_video_thumbnail={img}
                    srl_video_caption={x.title}
                  >
                    <source
                      src={videoUrl}
                      type={x.media?.file.contentType}
                    />
                    Your browser does not support the video tag.
                  </video>
                </VideoDiv>
              );
            } else {
              return (
                <a href={x.media.file.url} className="element_with_overlay">
                  <div className="overlay" srl_overlay="true">
                    <p>{x.title}</p>
                  </div>
                  <img width="360px" src={img} alt={x.title} />
                </a>
              );
            }
          })}
        </Wrap>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
};
